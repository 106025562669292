import { Bars3Icon } from "@heroicons/react/24/outline";
import Link from "next/link";
import Image from "next/image";
import { cn } from "@/lib/utils";
import { usePathname } from "next/navigation";
import mixpanel from "@/utils/mixpanel";

type tabOption = {
  name: string;
  slug: string;
  mixpanelEvent: string;
};
const tabOptions: tabOption[] = [
  {
    name: "Masterclasses",
    slug: "masterclass",
    mixpanelEvent: "explore_masterclasses",
  },
  {
    name: "Bootcamps",
    slug: "bootcamps",
    mixpanelEvent: "explore_bootcamps",
  },
  {
    name: "Blogs",
    slug: "blogs",
    mixpanelEvent: "explore_blog",
  },
  {
    name: "AstroLearn Gold",
    slug: "astro-gold",
    mixpanelEvent: "explore_gold",
  },
];

export default function Header({ setIsMenuBar }: { setIsMenuBar: any }) {
  const pathname = usePathname();

  return (
    <div className="py-3 bg-white top-0 sticky z-40 p-4 flex justify-center items-center shadow-md">
      <header className="flex items-center justify-between w-full max-w-[1440px] mx-auto lg:px-8">
        <div className="flex justify-center items-center w-full md:w-auto">
          <Bars3Icon
            className="w-[39px] rounded-md lg:hidden"
            onClick={() => {
              setIsMenuBar((prev: boolean) => !prev);
            }}
          />
          <Link
            className="flex gap-2 w-full no-underline hover:no-underline"
            href="/"
          >
            <Image
              width={39}
              height={39}
              src="/img/logo.png"
              alt="logo"
              className="aspect-square rounded-lg max-lg:ml-2"
            />
            <h3 className="text-black font-medium hover:no-underline font-montserrat lg:text-[25px] xl:text-[30px] flex items-center justify-center gap-1 md:block">
              AstroLearn
            </h3>
          </Link>
        </div>

        <div className="hidden lg:flex items-center max-lg:gap-3 gap-8">
          {tabOptions.map((tabOption: tabOption) => {
            const isActive = pathname === `/${tabOption.slug}`;
            return (
              <Link
                href={`/${tabOption.slug}`}
                key={tabOption.name}
                className="font-lato text-[18px] font-medium text-black hover:no-underline flex items-center justify-center gap-1"
                onClick={() => {
                  mixpanel.track(tabOption?.mixpanelEvent, {
                    page_location: "Homepage",
                    button_location: "navbar",
                  });
                }}
              >
                <span
                  className={cn(
                    "hover:scale-105 transition-all hover:font-semibold"
                  )}
                >
                  {tabOption.name}
                  <div
                    className={cn(
                      "h-[1.5px] rounded-md w-full transition-all ease-linear duration-300",
                      isActive && "bg-black scale-x-100"
                    )}
                  />
                  <div
                    className={cn(
                      "h-[1.5px] rounded-md bg-black w-full transition-all ease-linear delay-75 duration-500 -mt-[2px] scale-x-0 hover:scale-x-100 origin-left",
                      !isActive && ""
                    )}
                  />
                </span>
                {tabOption.name == "Masterclasses" && (
                  <b className="bg-[#FFD700] rounded-full text-black text-[10px] px-2 py-1">
                    FREE
                  </b>
                )}
              </Link>
            );
          })}
        </div>
      </header>
    </div>
  );
}
